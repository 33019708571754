<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  <environment-selector></environment-selector>
  Fp Pass<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
 <br />
 </div>
</div>
